import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1500.000000 1500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M5656 10606 c-82 -30 -155 -76 -219 -141 -55 -55 -90 -112 -126 -204
l-26 -66 -3 -1749 -2 -1748 70 65 c78 72 194 135 300 164 88 23 273 24 356 1
242 -66 435 -248 510 -483 37 -113 43 -261 17 -371 l-19 -81 260 150 c264 152
499 288 1926 1112 730 422 800 465 861 526 162 162 200 419 93 630 -29 58
-124 163 -179 197 -22 14 -458 267 -970 562 -511 295 -1267 732 -1679 970
-412 239 -777 445 -810 459 -51 21 -81 26 -176 28 -102 3 -123 1 -184 -21z
m1053 -910 c471 -272 1232 -711 1691 -976 459 -264 841 -487 849 -495 7 -7 15
-33 18 -57 4 -37 1 -49 -18 -70 -22 -24 -760 -458 -779 -458 -4 0 -34 44 -65
98 -367 619 -916 1069 -1605 1314 -275 98 -596 154 -912 160 l-158 3 0 458 0
459 29 29 c20 20 39 29 62 29 24 0 242 -121 888 -494z m-623 -937 c224 -22
459 -79 660 -159 460 -184 827 -464 1121 -854 74 -99 214 -326 208 -337 -6 -9
-377 -224 -387 -224 -3 0 -36 48 -72 108 -127 209 -302 411 -486 560 -357 289
-837 457 -1307 457 l-93 0 0 230 0 230 123 0 c68 0 173 -5 233 -11z m-130
-899 c278 -23 507 -98 739 -243 176 -109 316 -238 443 -406 62 -83 152 -228
152 -245 0 -10 -366 -227 -395 -234 -6 -1 -35 37 -63 85 -159 268 -405 454
-713 538 -73 20 -127 28 -241 32 l-148 6 0 232 c0 127 3 235 7 238 9 10 70 9
219 -3z"
          />
          <path
            d="M5750 6610 c-116 -25 -221 -109 -274 -220 -28 -59 -31 -74 -31 -160
1 -75 6 -107 24 -152 32 -81 107 -160 189 -200 62 -30 75 -33 167 -33 92 0
105 3 167 32 300 142 297 563 -5 703 -70 33 -165 45 -237 30z"
          />
          <path
            d="M6102 5554 c-15 -10 -22 -25 -22 -49 0 -59 77 -88 120 -45 60 60 -27
144 -98 94z"
          />
          <path
            d="M4540 5480 l0 -60 130 0 130 0 0 -370 0 -370 60 0 60 0 0 370 0 370
125 0 125 0 0 60 0 60 -315 0 -315 0 0 -60z"
          />
          <path
            d="M8220 5531 c-121 -26 -211 -129 -249 -285 -17 -67 -15 -229 4 -299
45 -172 156 -270 305 -270 108 0 194 53 255 157 35 59 65 185 65 271 0 86 -30
212 -65 271 -70 119 -195 181 -315 155z m155 -145 c106 -81 137 -325 60 -482
-49 -101 -139 -138 -227 -95 -75 36 -117 121 -125 257 -11 161 36 291 119 333
57 29 124 24 173 -13z"
          />
          <path
            d="M8670 5111 l0 -431 100 0 100 0 0 125 0 125 63 0 c218 0 339 82 367
249 5 28 8 -61 9 -226 l1 -273 95 0 95 0 0 198 c0 137 4 204 13 222 21 42 74
70 132 70 l52 0 7 53 c3 28 6 66 6 84 0 30 -3 32 -43 38 -47 7 -111 -6 -142
-29 -27 -20 -32 -20 -40 4 -6 19 -14 21 -88 18 l-82 -3 -6 -45 c-6 -43 -6 -44
-7 -8 -4 90 -100 211 -190 239 -20 6 -128 14 -239 16 l-203 5 0 -431z m380
216 c14 -7 33 -29 44 -51 16 -32 17 -44 7 -77 -17 -58 -41 -69 -143 -69 l-88
0 0 105 0 105 78 0 c42 0 88 -6 102 -13z"
          />
          <path
            d="M7671 5375 c-61 -86 -111 -159 -111 -164 0 -8 36 -43 57 -55 7 -4 36
25 75 75 l63 81 3 -316 2 -317 58 3 57 3 0 420 0 420 -47 3 -47 3 -110 -156z"
          />
          <path
            d="M9980 5350 c-108 -23 -200 -94 -244 -189 -31 -67 -31 -235 1 -303 72
-157 256 -233 433 -179 61 19 81 31 130 79 80 79 103 143 98 268 -5 110 -36
183 -105 243 -77 68 -209 102 -313 81z m150 -192 c59 -30 82 -72 82 -148 0
-75 -28 -125 -86 -151 -55 -25 -94 -24 -146 4 -55 29 -80 75 -80 145 0 133
115 208 230 150z"
          />
          <path
            d="M7178 5336 c-63 -17 -112 -52 -139 -99 -27 -49 -24 -148 6 -190 32
-44 109 -76 210 -86 103 -11 121 -16 153 -44 43 -39 24 -98 -41 -128 -87 -39
-202 -12 -234 55 -16 35 -19 36 -71 36 l-55 0 6 -36 c10 -61 52 -113 115 -142
50 -23 69 -27 152 -26 149 1 234 48 260 146 24 89 -17 167 -109 207 -29 12
-91 25 -147 31 -105 11 -140 26 -150 65 -15 60 20 101 99 116 76 14 177 -25
177 -68 0 -9 17 -13 59 -13 l59 0 -8 37 c-27 119 -182 182 -342 139z"
          />
          <path
            d="M5150 5333 c0 -5 47 -152 104 -328 l104 -320 45 -3 46 -3 36 118 c21
65 52 168 70 228 l32 110 17 -55 c9 -30 39 -133 68 -227 l52 -173 46 0 46 0
102 318 c57 174 103 323 102 330 0 9 -15 12 -57 10 l-56 -3 -28 -90 c-16 -49
-45 -152 -65 -228 -21 -75 -41 -134 -44 -130 -4 5 -35 108 -70 231 l-63 223
-51 -3 -51 -3 -64 -228 c-36 -125 -68 -222 -72 -215 -4 7 -30 96 -59 198 -74
263 -67 250 -134 250 -31 0 -56 -3 -56 -7z"
          />
          <path d="M6090 5010 l0 -330 60 0 60 0 0 330 0 330 -60 0 -60 0 0 -330z" />
          <path
            d="M6340 5010 l0 -330 55 0 55 0 0 180 c0 215 9 268 57 321 42 47 87 63
164 57 70 -5 105 -27 134 -84 18 -35 20 -63 23 -256 l4 -218 54 0 54 0 0 209
c0 178 -3 218 -19 270 -38 122 -102 171 -233 179 -102 6 -139 -2 -193 -43
l-45 -35 0 40 0 40 -55 0 -55 0 0 -330z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
